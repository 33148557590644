<template>
  <app-header/>
    <Suspense>
      <template #default>
         <app-arcade/>
      </template>
      <template #fallback>
        <app-loading />
      </template>
    </Suspense>
  <app-footer/>
</template>

<script>
import AppFooter from '../../components/layouts/AppFooter.vue'
import AppHeader from '../../components/layouts/AppHeader.vue'
import AppArcade from '../../components/Pages/GamesCategorys/AppArcade.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { Suspense } from "vue";
export default {
  components: { AppHeader, AppArcade, AppFooter, AppLoading, Suspense },
  name: 'Arcade'
}
</script>

<style>

</style>